import { createApp } from "/node_modules/.vite/deps/vue.js?v=10d082fd"
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=10d082fd";
import App from "/src/App.vue"
import "/src/assets/scss/tailwind.scss";
import router from "/src/router/index.js";
import PrimeVue from "/node_modules/.vite/deps/primevue_config.js?v=10d082fd";
import AnimateOnScroll from "/node_modules/.vite/deps/primevue_animateonscroll.js?v=10d082fd";
import __vite__cjsImport7_vueTheMask from "/node_modules/.vite/deps/vue-the-mask.js?v=10d082fd"; const VueTheMask = __vite__cjsImport7_vueTheMask.__esModule ? __vite__cjsImport7_vueTheMask.default : __vite__cjsImport7_vueTheMask;
import i18n from "/src/i18n.js";

// Vuetify
import "/node_modules/vuetify/lib/styles/main.css"
import { createVuetify } from "/node_modules/.vite/deps/vuetify.js?v=10d082fd"
import * as components from "/node_modules/.vite/deps/vuetify_components.js?v=10d082fd"
import * as directives from "/node_modules/.vite/deps/vuetify_directives.js?v=10d082fd"

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App)
  .use(createPinia())
  .use(router)
  .use(PrimeVue)
  .use(vuetify)
  .use(VueTheMask)
  .use(i18n)
  .directive('animateonscroll', AnimateOnScroll)
  .mount('#app')
